import { useEffect, useState } from 'react';
import { useAuthQueryParams } from './hooks/useQueryParams';
import LoadingSpinner from './components/LoadingSpinner';
import { AblyProvider } from 'hooks/useAbly';
import {
  authenticateAndSaveUser,
  getToken,
  getUserLocalStorage,
  isAuthenticated,
  redirectToLogin,
  redirectToLogout,
} from './utils/authUtils';
import Quoting from 'features/quoting/components/Quoting';
import { FeatureFlagProvider } from 'utils/featureFlagContext';

// TODO: Update ably to use 2.X version?
const AblyProviderWithChildren: React.FC<
  React.PropsWithChildren<React.PropsWithChildren<{}>>
> = AblyProvider;

const App = () => {
  const [isLoading, setIsLoading] = useState(true);
  const {
    queryParams: { email, token },
  } = useAuthQueryParams();

  useEffect(() => {
    const authenticateUser = async () => {
      // if we have an email and token we always need to go revalidate
      // a new token could have been generated when core-web routes to our site
      if (email && token) {
        try {
          await authenticateAndSaveUser(token, email);
          setIsLoading(false);
          return;
        } catch (error) {
          redirectToLogout();
        }
      }

      if (isAuthenticated()) {
        const currentUser = getUserLocalStorage();
        const currentUserToken = getToken();
        await authenticateAndSaveUser(currentUserToken!, currentUser?.email!);
        setIsLoading(false);
        return;
      }
      // when we logout core-web will redirect here without the token and email
      // and expects us to redirect back to the proper login url
      redirectToLogin();
    };
    authenticateUser();
  }, [email, token]);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <FeatureFlagProvider>
      <AblyProviderWithChildren>
        <Quoting />
      </AblyProviderWithChildren>
    </FeatureFlagProvider>
  );
};

export default App;
